import { Component } from "react";
import { AiFillVideoCamera } from "react-icons/ai";
import { FaPhotoVideo } from "react-icons/fa";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

class Intro extends Component {
  render() {
    return (
      <div className="services__intro">
        <div className="pos-rel">
          <div className="services__intro__image">
            <img
              src="https://images.unsplash.com/photo-1567515004624-219c11d31f2e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1pYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
              alt=""
            />
          </div>
          <Swiper
            className="services__intro__miniSlider"
            spaceBetween={30}
            pagination={{
              clickable: true
            }}
            modules={[Pagination]}
          >
            <SwiperSlide>
              <div className="icon">
                <AiFillVideoCamera />
              </div>
              <p>Get interesting and attractive scripts written by our experts</p>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon">
                <FaPhotoVideo /> {/* You can use the same icon here if you prefer */}
              </div>
              <p>Get Content planned, scripted, edited and optimized for retention</p>
            </SwiperSlide>
          </Swiper>
        </div>
        <div>
          <div className="services__intro__text">
            <h1>Would few extra thousands views for your business help?</h1>
            <p className="text">
            I assume yes; that would help you get more followers, leads, and customers. So what's holding you back? Get a personalized offer for you that works for you. Book a free 15 min stategy call today-
            </p>
            <a href="https://calendly.com/razzomediaofficial" className="btn btn-primary" target="_blank">
              Book a Call
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;

