import { Component } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./style.css";
class About extends Component {
  constructor() {
    super();
    this.state = {
      percentage: 90
    };
  }
  render() {
    return (
      <section className="about__container">
        <div className="container">
          <div>
            <h1>Organic content is a goldmine</h1>
            <p className="text">
            these platforms offer a unique opportunity to reach a vast audience in a short span of time, and it's all about being authentic and creative.
            <br/>
            <br/>
            Organic content can go viral in a matter of seconds. It's all about capturing attention with eye-catching videos, catchy music, and relatable stories. These platforms are a level playing field, where anyone with a great idea can strike gold.
            <br/>
            <br/>
            Looking to build your presence online?
            </p>
            <div className="about__buttons">
            <a href="https://calendly.com/razzomediaofficial" className="btn btn-primary" target="_blank">
              Book a Call
            </a>

              <a href="#services">
                <span>Our Services</span>
                <AiOutlineArrowDown />
              </a>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="about__image">
              <img
                src="https://st4.depositphotos.com/17586788/i/600/depositphotos_247586928-stock-photo-a-successful-business-girl-in.jpg"
                alt="about"
              />
            </div>
            <div className="about__progress">
              <div className="progress_bar">
                <CircularProgressbar
                  value={this.state.percentage}
                  text={`${this.state.percentage}%`}
                />
              </div>
              <h4>
                of Brands don't
                <br />
                <small>Leverage content</small>
              </h4>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
