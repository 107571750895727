import { Component } from "react";
import razzo from "../img/logo3.png";

class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <img src={ razzo } alt="Logo" />
      </div>
    );
  }
}
export default Logo;
