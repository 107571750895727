import { Component } from "react";
import { CgInstagram } from "react-icons/cg";
import { FaTwitterSquare } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa';
import { AiFillYoutube } from 'react-icons/ai';
import "./style.css";
class Footer extends Component {
  render() {
    return (
      <footer id="contact" className="container footer__container">
        <div className="about">
          <h3>LOGO</h3>
          <p className="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste saepe
            voluptatum repellat placeat aperiam. Molestiae consectetur molestias
            magni laudantium doloremque!
          </p>
          <ul className="footer__socials">
            <li>
            <a href="https://www.instagram.com/therazzomedia/" target="_blank">
          <CgInstagram />
        </a>
            </li>
            <li>
            <a href="https://twitter.com/therazzomedia" target="_blank">
          <FaTwitterSquare />
        </a>
            </li>
            <li>
            <a href="https://www.tiktok.com/@razzomedia" target="_blank">
        <FaTiktok />
        </a>
            </li>
            <li>
            <a href="https://www.youtube.com/@razzomedia" target="_blank">
        <AiFillYoutube />
        </a>
            </li>
          </ul>
        </div>
        <div className="services">
          <h3></h3>
          <ul>
            
          </ul>
        </div>
        <div className="contact">
          <h3>Contact</h3>
          <ul>
            
            <li>
              <a href="mailto:raaz@razzomedia.com" className="text">
                Email: raaz@razzomedia.com
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
