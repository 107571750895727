import { Component } from "react";
import { CgInstagram } from "react-icons/cg";
import { FaTwitterSquare } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa';
import { AiFillYoutube } from 'react-icons/ai';

class Socials extends Component {
  render() {
    return (
      <div className="socials">
        <a href="https://www.instagram.com/therazzomedia/" target="_blank">
          <CgInstagram />
        </a>
        <a href="https://twitter.com/therazzomedia" target="_blank">
          <FaTwitterSquare />
        </a>
        <a href="https://www.tiktok.com/@razzomedia" target="_blank">
        <FaTiktok />
        </a>
        <a href="https://www.youtube.com/@razzomedia" target="_blank">
        <AiFillYoutube />
        </a>
      </div>
    );
  }
}
export default Socials;
