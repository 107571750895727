import { Component } from "react";
import { BiBrain } from "react-icons/bi";
import { BsLaptop } from "react-icons/bs";
import { AiFillVideoCamera } from "react-icons/ai";
import { HiSpeakerphone } from "react-icons/hi";

class ServicesComp extends Component {
  render() {
    return (
      <div className="services__servicesComp">
        <div>
          <h1>Our Servcices</h1>
          <a href="https://calendly.com/razzomediaofficial" className="btn btn-primary" target="_blank">
              Book a Call
            </a>
        </div>
        <div>
          <div>
            <div className="icon">
              <BiBrain  />
            </div>
            <h4>Content strategy</h4>
            <p>Plan and processes in place to maximize oraganic views.</p>
          </div>
          <div>
            <div className="icon">
              <BsLaptop />
            </div>
            <h4>Consulting services</h4>
            <p>Let our professionals guide you through the journey.</p>
          </div>
          <div>
            <div className="icon">
              <AiFillVideoCamera />
            </div>
            <h4>Editing and optimizing</h4>
            <p>Get videos edited and optimized for maximum results.</p>
          </div>
          <div>
            <div className="icon">
              <HiSpeakerphone />
            </div>
            <h4>Marketing and product</h4>
            <p>Get professional help with product creation and marketing.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicesComp;
